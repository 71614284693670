import React from "react";
import Layout from "../components/layout";
import { Grid, Typography } from "@material-ui/core";
import GlobalConstants from "../helpers/constants";
import { Link } from "gatsby";

const NotFoundPage = (): JSX.Element => (
  <Layout>
    <Grid item xs={12}>
      <Typography variant="h6">{GlobalConstants.PageNotFound}</Typography>
      <Typography variant="body2">
        <p>
          {GlobalConstants.AccessDenied}
          Click <Link to="/">here</Link> to return Home.
        </p>
      </Typography>
    </Grid>
  </Layout>
);

export default NotFoundPage;
